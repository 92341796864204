import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import data from './data'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...data,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        guest: true,
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        auth: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // Redirect if authenticated
  if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }
})

export default router

import routerAdminCallCenter from "./router-admin-call-center";
import routerAdminTukangPro from "./router-admin-tukang-pro";
import routerSuperAdmin from "./router-super-admin";

var data = [];
var role_name = JSON.parse(localStorage.getItem("userData"));
if (role_name) {
  switch (role_name.role_name) {
    case "Super Admin":
      data = routerSuperAdmin;
      break;
    case "Admin Call Center":
      data = routerAdminCallCenter;
      break;
    case "Admin Tukang Pro":
      data = routerAdminTukangPro;
      break;
    default:
      break;
  }
}

export default data;

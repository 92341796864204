import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.request.use(
  request => {
    if (request.data) {
      request.headers.common['Content-Type'] = 'multipart/form-data'
    }
    return request
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      window.location.href = `${window.location.origin}/login`
    }

    if (error.response.status === 403) {
      window.location.href = `${window.location.origin}/error-403`
    }

    return Promise.reject(error)
  },
)

console.log(axiosIns.defaults.baseURL)

export default axiosIns

export default [
  {
    path: '/request-order',
    name: 'request-order',
    component: () => import('@/views/data/request-order/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Request Order',
      breadcrumb: [
        {
          text: 'Request Order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-order/:slug',
    name: "detail-reques-order",
    component: () => import("@/views/data/request-order/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Request Order",
      navActiveLink: "Request Order",
      breadcrumb: [
        {
          text: "Detail Request Order",
          active: true,
        },
      ],
    },
  },
];

export default [
  {
    path: "/request-order-tukang-pro",
    name: "request-order-tukang-pro",
    component: () => import("@/views/data/request-order-tukang-pro/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Request Order",
      breadcrumb: [
        {
          text: "Request Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/request-order-tukang-pro/:slug",
    name: "detail-reques-order-tukang-pro",
    component: () => import("@/views/data/request-order-tukang-pro/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Request Order",
      navActiveLink: "Request Order",
      breadcrumb: [
        {
          text: "Detail Request Order",
          active: true,
        },
      ],
    },
  },
];
